export const cxConditions={
yxdq:[
    {
        name:"不限",
        value:"",
        isActive:true
    },
    {
        name:"北京",
        value:"11",
        isActive:false
    },
    {
        name:"天津",
        value:"12",
        isActive:false
    },
    {
        name:"上海",
        value:"31",
        isActive:false
    },
    {
        name:"江苏",
        value:"32",
        isActive:false
    },
    {
        name:"浙江",
        value:"33",
        isActive:false
    },
    {
        name:"广东",
        value:"44",
        isActive:false
    },
    {
        name:"湖北",
        value:"42",
        isActive:false
    },
    {
        name:"湖南",
        value:"43",
        isActive:false
    },
    {
        name:"山东",
        value:"37",
        isActive:false
    },
    {
        name:"福建",
        value:"35",
        isActive:false
    },
    {
        name:"四川",
        value:"51",
        isActive:false
    },
    {
        name:"重庆",
        value:"50",
        isActive:false
    },
    {
        name:"陕西",
        value:"61",
        isActive:false
    },
    {
        name:"云南",
        value:"53",
        isActive:false
    },
    {
        name:"河北",
        value:"13",
        isActive:false
    },
    {
        name:"山西",
        value:"14",
        isActive:false
    },
    {
        name:"吉林",
        value:"22",
        isActive:false
    },
    {
        name:"黑龙江",
        value:"23",
        isActive:false
    },
    {
        name:"安徽",
        value:"34",
        isActive:false
    },
    {
        name:"河南",
        value:"41",
        isActive:false
    },
    {
        name:"江西",
        value:"36",
        isActive:false
    },
    {
        name:"海南",
        value:"46",
        isActive:false
    },
    {
        name:"辽宁",
        value:"21",
        isActive:false
    },
    {
        name:"广西",
        value:"45",
        isActive:false
    },
    {
        name:"贵州",
        value:"52",
        isActive:false
    },
    {
        name:"甘肃",
        value:"62",
        isActive:false
    },
    {
        name:"青海",
        value:"63",
        isActive:false
    },
    {
        name:"宁夏",
        value:"64",
        isActive:false
    },
    {
        name:"新疆",
        value:"65",
        isActive:false
    },
    {
        name:"西藏",
        value:"54",
        isActive:false
    },
    {
        name:"内蒙古",
        value:"15",
        isActive:false
    }
],
hotCity:[
    {
        name:"不限",
        value:"",
        isActive:true
    },
    {
        name:"北京",
        value:"11",
        isActive:false
    },
    {
        name:"上海",
        value:"31",
        isActive:false
    },
    {
        name:"深圳",
        value:"4403",
        isActive:false
    },
    {
        name:"广州",
        value:"4401",
        isActive:false
    },
    {
        name:"武汉",
        value:"4201",
        isActive:false
    },
    {
        name:"西安",
        value:"6101",
        isActive:false
    },
    {
        name:"南京",
        value:"3201",
        isActive:false
    },
    {
        name:"天津",
        value:"12",
        isActive:false
    },
    {
        name:"长沙",
        value:"4301",
        isActive:false
    },
    {
        name:"成都",
        value:"5101",
        isActive:false
    },
    {
        name:"长春",
        value:"2201",
        isActive:false
    },
    {
        name:"重庆",
        value:"50",
        isActive:false
    },
    {
        name:"沈阳",
        value:"2101",
        isActive:false
    },
    {
        name:"哈尔滨",
        value:"2301",
        isActive:false
    },
    {
        name:"杭州",
        value:"3301",
        isActive:false
    },
    {
        name:"济南",
        value:"3701",
        isActive:false
    },
    {
        name:"青岛",
        value:"3702",
        isActive:false
    },
    {
        name:"郑州",
        value:"4101",
        isActive:false
    },
    {
        name:"福州",
        value:"3501",
        isActive:false
    },
    {
        name:"厦门",
        value:"3502",
        isActive:false
    },
    {
        name:"昆明",
        value:"5301",
        isActive:false
    },
    {
        name:"南宁",
        value:"4501",
        isActive:false
    },
    {
        name:"海口",
        value:"4601",
        isActive:false
    },
    {
        name:"南昌",
        value:"3601",
        isActive:false
    },
    {
        name:"贵阳",
        value:"5201",
        isActive:false
    },
    {
        name:"合肥",
        value:"3401",
        isActive:false
    },
    {
        name:"太原",
        value:"1401",
        isActive:false
    },
    {
        name:"大连",
        value:"2102",
        isActive:false
    }
],
yxType:[
    {
        name:"不限",
        value:"",
        isActive:true
    },
    {
        name:"综合类",
        value:"1",
        isActive:false
    },
    {
        name:"理工类",
        value:"2",
        isActive:false
    },
    {
        name:"财经类",
        value:"3",
        isActive:false
    },
    {
        name:"农林类",
        value:"4",
        isActive:false
    },
    {
        name:"林业类",
        value:"5",
        isActive:false
    },
    {
        name:"医药类",
        value:"6",
        isActive:false
    },
    {
        name:"师范类",
        value:"7",
        isActive:false
    },
    {
        name:"体育类",
        value:"8",
        isActive:false
    },
    {
        name:"语言类",
        value:"9",
        isActive:false
    },
    {
        name:"政法类",
        value:"10",
        isActive:false
    },
    {
        name:"艺术类",
        value:"11",
        isActive:false
    },
    {
        name:"民族类",
        value:"12",
        isActive:false
    },
    {
        name:"军事类",
        value:"13",
        isActive:false
    },
    {
        name:"交叉学科类",
        value:"14",
        isActive:false
    }
],
bxType:[
    {
        name:"不限",
        value:"",
        isActive:true
    },
    {
        name:"公办",
        value:"1",
        isActive:false
    },
    {
        name:"民办",
        value:"2",
        isActive:false
    },
    // {
    //     name:"独立院校",
    //     value:"3",
    //     isActive:false
    // },
    {
        name:"中外合作办学",
        value:"4",
        isActive:false
    }
],
pcType:[
    {
        name:"不限",
        value:"0",
        isActive:true
    },
    {
        name:"普通本科批",
        value:"6",
        xgzg:"0",
        isActive:false
    },
    {
        name:"国家专项",
        value:"6",
        xgzg:"1",
        isActive:false
    },
    {
        name:"地方专项",
        value:"6",
        xgzg:"2",
        isActive:false
    },
    {
        name:"高职高专批",
        value:"7"
    }
],
yxts:[
    {
        name:"不限",
        value:"",
        isActive:true
    },
    {
        name:"211工程",
        value:"211",
        isActive:false
    },
    {
        name:"985高校",
        value:"985",
        isActive:false
    },
    {
        name:"双一流",
        value:"syl",
        isActive:false
    },
    {
        name:'101计划',
        value:'101',
        isActive:false
    }
]
};
export const tjConditions={
    yxdq:[
        {
            name:"不限",
            value:"",
            isActive:true
        },
        {
            name:"北京",
            value:"11",
            isActive:false
        },
        {
            name:"天津",
            value:"12",
            isActive:false
        },
        {
            name:"上海",
            value:"31",
            isActive:false
        },
        {
            name:"江苏",
            value:"32",
            isActive:false
        },
        {
            name:"浙江",
            value:"33",
            isActive:false
        },
        {
            name:"广东",
            value:"44",
            isActive:false
        },
        {
            name:"湖北",
            value:"42",
            isActive:false
        },
        {
            name:"湖南",
            value:"43",
            isActive:false
        },
        {
            name:"山东",
            value:"37",
            isActive:false
        },
        {
            name:"福建",
            value:"35",
            isActive:false
        },
        {
            name:"四川",
            value:"51",
            isActive:false
        },
        {
            name:"重庆",
            value:"50",
            isActive:false
        },
        {
            name:"陕西",
            value:"61",
            isActive:false
        },
        {
            name:"云南",
            value:"53",
            isActive:false
        },
        {
            name:"河北",
            value:"13",
            isActive:false
        },
        {
            name:"山西",
            value:"14",
            isActive:false
        },
        {
            name:"吉林",
            value:"22",
            isActive:false
        },
        {
            name:"黑龙江",
            value:"23",
            isActive:false
        },
        {
            name:"安徽",
            value:"34",
            isActive:false
        },
        {
            name:"河南",
            value:"41",
            isActive:false
        },
        {
            name:"江西",
            value:"36",
            isActive:false
        },
        {
            name:"海南",
            value:"46",
            isActive:false
        },
        {
            name:"辽宁",
            value:"21",
            isActive:false
        },
        {
            name:"广西",
            value:"45",
            isActive:false
        },
        {
            name:"贵州",
            value:"52",
            isActive:false
        },
        {
            name:"甘肃",
            value:"62",
            isActive:false
        },
        {
            name:"青海",
            value:"63",
            isActive:false
        },
        {
            name:"宁夏",
            value:"64",
            isActive:false
        },
        {
            name:"新疆",
            value:"65",
            isActive:false
        },
        {
            name:"西藏",
            value:"54",
            isActive:false
        },
        {
            name:"内蒙古",
            value:"15",
            isActive:false
        }
    ],
    hotCity:[
        {
            name:"不限",
            value:"",
            isActive:true
        },
        {
            name:"北京",
            value:"11",
            isActive:false
        },
        {
            name:"上海",
            value:"31",
            isActive:false
        },
        {
            name:"深圳",
            value:"4403",
            isActive:false
        },
        {
            name:"广州",
            value:"4401",
            isActive:false
        },
        {
            name:"武汉",
            value:"4201",
            isActive:false
        },
        {
            name:"西安",
            value:"6101",
            isActive:false
        },
        {
            name:"南京",
            value:"3201",
            isActive:false
        },
        {
            name:"天津",
            value:"12",
            isActive:false
        },
        {
            name:"长沙",
            value:"4301",
            isActive:false
        },
        {
            name:"成都",
            value:"5101",
            isActive:false
        },
        {
            name:"长春",
            value:"2201",
            isActive:false
        },
        {
            name:"重庆",
            value:"50",
            isActive:false
        },
        {
            name:"沈阳",
            value:"2101",
            isActive:false
        },
        {
            name:"哈尔滨",
            value:"2301",
            isActive:false
        },
        {
            name:"杭州",
            value:"3301",
            isActive:false
        },
        {
            name:"济南",
            value:"3701",
            isActive:false
        },
        {
            name:"青岛",
            value:"3702",
            isActive:false
        },
        {
            name:"郑州",
            value:"4101",
            isActive:false
        },
        {
            name:"福州",
            value:"3501",
            isActive:false
        },
        {
            name:"厦门",
            value:"3502",
            isActive:false
        },
        {
            name:"昆明",
            value:"5301",
            isActive:false
        },
        {
            name:"南宁",
            value:"4501",
            isActive:false
        },
        {
            name:"海口",
            value:"4601",
            isActive:false
        },
        {
            name:"南昌",
            value:"3601",
            isActive:false
        },
        {
            name:"贵阳",
            value:"5201",
            isActive:false
        },
        {
            name:"合肥",
            value:"3401",
            isActive:false
        },
        {
            name:"太原",
            value:"1401",
            isActive:false
        },
        {
            name:"大连",
            value:"2102",
            isActive:false
        }
    ],
    yxType:[
        {
            name:"不限",
            value:"",
            isActive:true
        },
        {
            name:"综合类",
            value:"1",
            isActive:false
        },
        {
            name:"理工类",
            value:"2",
            isActive:false
        },
        {
            name:"财经类",
            value:"3",
            isActive:false
        },
        {
            name:"农林类",
            value:"4",
            isActive:false
        },
        {
            name:"林业类",
            value:"5",
            isActive:false
        },
        {
            name:"医药类",
            value:"6",
            isActive:false
        },
        {
            name:"师范类",
            value:"7",
            isActive:false
        },
        {
            name:"体育类",
            value:"8",
            isActive:false
        },
        {
            name:"语言类",
            value:"9",
            isActive:false
        },
        {
            name:"政法类",
            value:"10",
            isActive:false
        },
        {
            name:"艺术类",
            value:"11",
            isActive:false
        },
        {
            name:"民族类",
            value:"12",
            isActive:false
        },
        {
            name:"军事类",
            value:"13",
            isActive:false
        },
        {
            name:"交叉学科类",
            value:"14",
            isActive:false
        }
    ],
    bxType:[
        {
            name:"不限",
            value:"",
            isActive:true
        },
        {
            name:"公办",
            value:"1",
            isActive:false
        },
        {
            name:"民办",
            value:"2",
            isActive:false
        },
        // {
        //     name:"独立院校",
        //     value:"3",
        //     isActive:false
        // },
        {
            name:"中外合作办学",
            value:"4",
            isActive:false
        }
    ],
    pcType:[
        {
            name:"普通本科批",
            value:"6",
            xgzg:"0",
            isActive:true
        },
        {
            name:"普通本科批(含国家专项)",
            value:"6",
            xgzg:"1",
            isActive:false
        },
        {
            name:"普通本科批(含地方专项)",
            value:"6",
            xgzg:"2",
            isActive:false
        },
        {
            name:"普通本科批(含国家专项和地方专项)",
            value:"6",
            xgzg:"3",
            isActive:false
        },
        {
            name:"高职高专批",
            value:"7",
            isActive:false
        }
    ],
    yxts:[
        {
            name:"不限",
            value:"",
            isActive:true
        },
        {
            name:"211工程",
            value:"211",
            isActive:false
        },
        {
            name:"985高校",
            value:"985",
            isActive:false
        },
        {
            name:"双一流",
            value:"syl",
            isActive:false
        },
        {
            name:'101计划',
            value:'101',
            isActive:false
        }
    ],
    type:[
        {
            name:"冲刺型",
            value:"0",
            isActive:true
        },
        {
            name:"稳妥型",
            value:"1",
            isActive:false
        },
        {
            name:"保守型",
            value:"2",
            isActive:false
        }
    ]
};
