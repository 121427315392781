<template>
    <div class="school-finder">
        <div class="card-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <Conditions
        :conditionsExt="tjConditions"
        :requestParam="requestParam"/>
    </div>
</template>
<script>
import Conditions from '../components/TjConditions.vue';
import {tjConditions} from '../common/cxContains';
import {mapState} from 'vuex';
export default {
props:['sxkmChangeReloadYxtj'],
data(){
    return{
        tjConditions:tjConditions,
        requestParam:{
            gkfs:"",
            kl:"",
            yxmc:"",
            yxsd:"",
            rmcs:"",
            xxlb:"",
            bxlb:"",
            lqpc:"6",
            zxkm:"0",
            sf985:"0",
            sf211:"0",
            sfsyl:"0",
            sf101:"0",
            minzdtwf:"",
            maxzdtwf:"",
            minlqxc:"",
            maxlqxc:"",
            xzyx:"0",
            zxlx:"",
            gender:"",
            sfzhl:"",
            tjlb:"0",
            pageNum:"1",
            pageSize:"10",
            username:"",
            zxkma:"",
            zxkmb:"",
            size:"10"
        },
        reloadTimer:null
    };
},
computed:{
    ...mapState(["userInfo"])
},
components:{
    Conditions
},
mounted(){
this.requestParam.kl=this.userInfo.sxkm;
this.requestParam.zxkma=this.userInfo.zxkma;
this.requestParam.zxkmb=this.userInfo.zxkmb;
this.requestParam.gkfs=this.userInfo.gkfs;
this.reloadTimer=setInterval(() => {
    if(this.sxkmChangeReloadYxtj&&this.sxkmChangeReloadYxtj==='true'){
        clearInterval(this.reloadTimer);
        this.$router.push({
            path:'/schoolRecommend',
            query:{
                time:(new Date().getMilliseconds())
            }
        });
        location.reload();
    }
}, 1000);
}
};
</script>
<style lang="less" scoped>
.school-finder{
    min-width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 380px);
    min-height: -webkit-calc(100vh - 380px);
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
}
</style>
